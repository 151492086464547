import Vue from "vue";

const state = {
  users: [],
};

const mutations = {
  SET_USERS_TABLE: (state, payload) => {
    state.users = payload;
  },
};

const actions = {
  fetchUsersTable({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.users
        .getUsers()
        .then(({ data }) => {
          commit("SET_USERS_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  fetchUsersByFilter({ commit }, str) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.users
        .getUsersByFilter(str)
        .then(({ data }) => {
          commit("SET_USERS_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  addCompanyUser(store, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.users
        .addCompanyUser(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  addLocationUser(store, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.users
        .addLocationUser(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  addWarehouseUser(store, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.users
        .addWarehouseUser(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  updateUser(store, { params, id }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.users
        .updateUser(params, id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  deleteUser(store, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.users
        .deleteUser(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getUsersTable: (state) => state.users,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
