import Vue from "vue";

const state = {
  locationsTable: [],
};

const mutations = {
  SET_LOCATIONS_TABLE: (state, payload) => {
    state.locationsTable = payload;
  },
  SET_LOCATION: (state, payload) => {
    state.location = payload;
  },
  SET_FLOW: (state, payload) => {
    state.flow = payload;
  },
};

const actions = {
  fetchLocationsTable({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.locations
        .getLocationsTable(params)
        .then(({ data }) => {
          commit("SET_LOCATIONS_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchLocationDetail({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.locations
        .getLocation(params)
        .then(({ data }) => {
          commit("SET_LOCATION", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchFlow({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.locations
        .getFlow(params)
        .then(({ data }) => {
          commit("SET_FLOW", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  addLocation(store, payload) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.locations
        .addLocation(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  deleteLocation(store, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.locations
        .deleteLocation(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getLocationsTable: (state) => state.locationsTable,
  getLocationDetail: (state) => state.location,
  getFlow: (state) => state.flow,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
