import "material-design-icons-iconfont/dist/material-design-icons.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import Vue from "vue";
import App from "./App.vue";
import "./assets/styles/app.scss";
import bionix from "./components/BxComponents";
import common from "./mixins/common";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Api from "./services/";
import store from "./store";
import DatetimePicker from "vuetify-datetime-picker";
import i18n from "./i18n";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

Vue.config.productionTip = false;

Vue.mixin(common);
Vue.use(bionix);
Vue.use(DatetimePicker);
Vue.use(Api, { store });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
