const files = require.context(".", false, /\.js$/);
const modules = {};

Array.prototype.flatten = function () {
  return this.reduce((prev, curr) => {
    return prev.concat(curr);
  }, []);
};

files.keys().forEach((key) => {
  if (key === "./index.js") return;
  modules[key.replace(/(\.\/|\.js)/g, "")] = files(key).default;
});

export default modules;
