import Vue from "vue";

const state = {
  cycles: [],
  cyclesTable: [],
  boxDetailTable: [],
};

const mutations = {
  SET_CYCLES: (state, payload) => {
    state.cycles = payload;
  },
  SET_CYCLES_TABLE: (state, payload) => {
    state.cyclesTable = payload;
  },
  SET_BOX_DETAIL_TABLE: (state, payload) => {
    state.boxDetailTable = payload;
  },
};

const actions = {
  fetchCycles({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.cycles
        .getCycles()
        .then(({ data }) => {
          commit("SET_CYCLES", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchCyclesTable({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.cycles
        .getCyclesTable(params)
        .then(({ data }) => {
          commit("SET_CYCLES_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  fetchBoxDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.cycles
        .getBoxDetail(id)
        .then(({ data }) => {
          commit("SET_BOX_DETAIL_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  downloadCsv(_, box_id) {
    return new Promise(() => {
      Vue.prototype.$api.cycles
        .downloadCsv(box_id)
        .then((blob) => {
          var binaryData = [];
          binaryData.push(blob.data);
          var url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: "application/*",
            })
          );
          var a = document.createElement("a");
          a.href = url;
          a.download = `${box_id}_file.csv`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); // afterwards we remove the element again
        })
        .catch((err) => {
          throw err;
        });
    });
  },

  downloadAllCsv(_, params) {
    return new Promise(() => {
      Vue.prototype.$api.cycles
        .downloadAllCsv(params)
        .then((blob) => {
          var binaryData = [];
          binaryData.push(blob.data);
          var url = window.URL.createObjectURL(
            new Blob(binaryData, {
              type: "application/*",
            })
          );
          var a = document.createElement("a");
          a.href = url;
          a.download = `file.csv`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); // afterwards we remove the element again
        })
        .catch((err) => {
          throw err;
        });
    });
  },

  deleteCycle(store, box) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.cycles
        .deleteCycle(box)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getCycles: (state) => state.cycles,
  getCyclesTable: (state) => state.cyclesTable,
  getBoxDetail: (state) => state.boxDetailTable,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
