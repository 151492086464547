import Vue from "vue";

const state = {
  alarms: [],
};

const mutations = {
  SET_ALARM: (state, payload) => {
    state.alarms = payload;
  },
};

const actions = {
  fetchAlarms({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.alarms
        .getAlarms(params)
        .then(({ data }) => {
          commit("SET_ALARM", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getAlarms: (state) => state.alarms,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
