<template>
  <div :class="rootClasses" :style="{ width: 'max-content' }">
    <div class="d-flex flex-row mx-5" v-if="cyclesAvg">
      <span :class="[_titleColor, 'text-center font-weight-bold']">{{
        `Cycles Average :`
      }}</span>
      <span :class="[_titleColor, 'text-center']" class="mx-2">{{
        cyclesAvg
      }}</span>
    </div>

    <div class="d-flex flex-column mx-5" v-if="username">
      <span :class="[_titleColor, 'text-center font-weight-bold']">{{
        $t("label.username")
      }}</span>
      <span :class="[_titleColor, 'text-center']">{{ username }}</span>
    </div>

    <div class="d-flex flex-column mx-5" v-if="startTime">
      <span :class="[_titleColor, 'text-center font-weight-bold']">{{
        $t("label.start-date")
      }}</span>
      <span :class="[_titleColor, 'text-center']">{{ _startDate() }}</span>
    </div>

    <div class="d-flex flex-column mx-5" v-if="endTime">
      <span :class="[_titleColor, 'text-center font-weight-bold']">{{
        $t("label.end-date")
      }}</span>
      <span :class="[_titleColor, 'text-center']">{{ _endDate() }}</span>
    </div>

    <div class="d-flex flex-column mx-5" v-if="description">
      <span :class="[_titleColor, 'text-center font-weight-bold']">{{
        $t("common.reader")
      }}</span>
      <span :class="[_titleColor, 'text-center']">{{ description }}</span>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  name: "box-regular",
  props: {
    cyclesAvg: {
      type: String,
    },
    username: {
      type: String,
    },
    startTime: {
      type: String,
    },
    endTime: {
      type: String,
    },
    description: {
      type: String,
    },
    titleColor: {
      type: [String],
      default: "white",
    },
    valueColor: {
      type: [String],
      default: "white",
    },
  },
  data() {
    return {
      rootClasses: [
        "d-flex",
        "flex-row",
        "align-center",
        "mx-10",
        "space-between",
        "w-100",
      ],
    };
  },
  computed: {
    _titleColor() {
      return this.titleColor + "--text";
    },
    _valueColor() {
      return this.valueColor + "--text";
    },
  },
  methods: {
    _startDate() {
      return formatDate(this.startTime);
    },
    _endDate() {
      return formatDate(this.endTime);
    },
  },
};
</script>

<style></style>
