import _axios from "axios";
import store from "../store";
export const API_BASE = process.env.VUE_APP_API_BASE;

export const axios = _axios.create({
  baseURL: API_BASE,
  headers: {
    Accept: "application/json",
  },
});

export default () => {
  // AÑADE EL TOKEN
  axios.interceptors.request.use(async (config) => {
    if (store.state.auth.token) {
      config.headers["Authorization"] = "Token " + store.state.auth.token;
    }
    return config;
  });
  //  GESTIONA LA RESPUESTA
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    auth: {
      login: (params) => axios.post("/pallet-token/", params),
    },
    dashboard: {
      getLocations: () => axios.get("/locations/"),
      getArea: (params) => axios.get("/areas/", { params }),
      getBoxstock: (loc_id) =>
        axios.get(
          `/boxstock/?area1__type=1&area1__location__id=${loc_id}&box__kill=False&limit=1`
        ),
      getBrands: () => axios.get("/brands/"),
    },
    cycles: {
      getCycles: () => axios.get(`/boxstock/cycles/`),
      getCyclesTable: (params) =>
        axios.get(`/boxstock/filter_by_cycles/`, { params }),
      downloadCsv: (box_id) => axios.get(`/boxareaseens/${box_id}/csv/`),
      downloadAllCsv: (params) =>
        axios.get(`boxareaseens/csv_total/`, { params }),
      getBoxDetail: (id) => axios.get(`/boxareaseens/${id}/`),
      deleteCycle: (id) => axios.delete(`/boxstock/${id}/`),
    },
    alarms: {
      getAlarms: (params) => axios.get(`/boxstock/alarms/`, { params }),
    },
    transit: {
      getTransit: (location_id, cycle_id) =>
        axios.get(
          `/boxstock/?area1__type=2&area1__location__id=${location_id}&cycles=${cycle_id}`
        ),

      getTransitPallets: (location_id) =>
        axios.get(
          `/boxstock/?area1__type=2&area1__location__id=${location_id}&box__kill=False&limit=1`
        ),
    },
    users: {
      getUsers: () => axios.get(`/companyusers/`),
      addCompanyUser: (params) => axios.post(`/companyusers/`, params),
      addLocationUser: (params) => axios.post(`/locationusers/`, params),
      addWarehouseUser: (params) => axios.post(`/warehouseusers/`, params),
      getLocationAreas: (id) => axios.get(`/locations/${id}/areas/`),
    },
    disputes: {
      getDisputesTable: () => axios.get(`/dispute/`),
      getCauses: () => axios.get(`/dispute/causes/`),
      getStates: () => axios.get(`/dispute/status/`),
      deleteDispute: (id) => axios.delete(`/dispute/${id}/`),
      getDispute: (id) => axios.get(`/dispute/${id}/`),
      updateDispute: (id, params) => axios.put(`/dispute/${id}/`, params),
      getWarehouseUsers: () => axios.get(`/companyusers/?warehouseuser__gte=0`),
    },
    locations: {
      getLocationsTable: () => axios.get(`/locations/`),
      getLocation: (id) => axios.get(`/locations/${id}/`),
      getFlow: (id) => axios.get(`/locations/${id}/flow/`),
      deleteLocation: (id) => axios.delete(`/locations/${id}/`),
      addLocation: (params) => axios.post(`/locations/`, params),
      updateLocation: (id, params) => axios.put(`/locations/${id}/`, params),
    },
  };
};
