import i18n from "@/i18n";
export default [
  {
    path: "dashboard",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "admin-operations" */ "@/views/admin/Dashboard.vue"
      ),
    meta: {
      title: i18n.tc("dashboard.title", 2),
      auth: true,
    },
  },
  {
    path: "pallets",
    name: "pallets",
    component: () => import("@/views/admin/PalletDashboard.vue"),
    meta: {
      title: i18n.tc("pallets.title", 2),
      auth: true,
    },
  },
  {
    path: "cycles",
    name: "cycles",
    component: () => import("@/views/admin/CycleDashboard.vue"),
    meta: {
      title: i18n.tc("cycle.title", 2),
      auth: true,
    },
  },
  {
    path: "cycles/box/:id",
    name: "box-detail",
    component: () => import("@/views/admin/BoxDetail.vue"),
    meta: {
      title: i18n.tc("box-detail.title", 2),
      auth: true,
    },
  },
  {
    path: "alarm/box/:id",
    name: "alarm-box-detail",
    component: () => import("@/views/admin/BoxDetail.vue"),
    meta: {
      title: i18n.tc("box-detail.title", 2),
      auth: true,
    },
  },
  {
    path: "download",
    name: "download-csv",
    component: () => import("@/views/admin/DownloadCsv.vue"),
    meta: {
      title: i18n.tc("download_csv.title", 2),
      auth: true,
    },
  },
  {
    path: "alarm",
    name: "alarm",
    component: () => import("@/views/admin/AlarmDashboard.vue"),
    meta: {
      title: i18n.tc("alarm.title", 2),
      auth: true,
    },
  },
  {
    path: "disputes/detail/:id",
    name: "dispute-detail",
    component: () => import("@/views/admin/disputes/DisputeDetail.vue"),
    meta: {
      title: i18n.tc("disputes.title", 2),
      auth: true,
    },
  },
  {
    path: "disputes",
    name: "disputes",
    component: () => import("@/views/admin/disputes/DisputeDashboard.vue"),
    meta: {
      title: i18n.tc("disputes.title", 2),
      auth: true,
    },
  },
  {
    path: "flows",
    name: "flows",
    component: () => import("@/views/admin/flow/FlowsDashboard.vue"),
    meta: {
      title: i18n.tc("locations.title", 2),
      auth: true,
    },
  },
  {
    path: "locations/detail/:id",
    name: "location-detail",
    component: () => import("@/views/admin/flow/LocationDetail.vue"),
    meta: {
      title: i18n.tc("locations.title", 2),
      auth: true,
    },
  },
  {
    path: "users",
    name: "users",
    component: () =>
      import(
        /* webpackChunkName: "admin-operations" */ "@/views/admin/Users.vue"
      ),
    meta: {
      title: i18n.tc("user.title", 2),
      auth: true,
    },
  },
];
