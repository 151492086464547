import Vue from "vue";

const state = {
  locations: null,
  areasPosioned: null,
  areasTransit: null,
  pallets: null,
  transit: null,
  transitPallets: null,
  brands: null,
};

const mutations = {
  SET_LOCATIONS: (state, payload) => {
    state.locations = payload;
  },
  SET_AREAS_POS: (state, payload) => {
    state.areasPosioned = payload;
  },
  SET_AREAS_TRANSIT: (state, payload) => {
    state.areasTransit = payload;
  },
  SET_PALLETS: (state, payload) => {
    state.pallets = payload;
  },
  SET_TRANSIT: (state, payload) => {
    state.transit = payload;
  },
  SET_TRANSIT_PALLETS: (state, payload) => {
    state.transitPallets = payload;
  },
  SET_BRANDS: (state, payload) => {
    state.brands = payload;
  },
};

const actions = {
  fetchLocation({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.dashboard
        .getLocations()
        .then(async ({ data }) => {
          commit("SET_LOCATIONS", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  fetchArea({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.dashboard
        .getArea(params)
        .then(async ({ data }) => {
          if (params.type === 1) {
            commit("SET_AREAS_POS", data);
          } else {
            commit("SET_AREAS_TRANSIT", data);
          }
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  fetchBrands({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.dashboard
        .getBrands()
        .then(async ({ data }) => {
          commit("SET_BRANDS", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  // Fetch pallets array on location basis
  async fetchBoxStock({ commit }, data) {
    const pallets = await Promise.all(
      data?.map(async (el) => {
        return await Vue.prototype.$api.dashboard
          .getBoxstock(el.id)
          .then(({ data }) => data);
      })
    );
    commit("SET_PALLETS", pallets);
  },

  /**
   * Report Action
   */
  fetchOperationsReport({ commit }, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.operations
        .getReports(id)
        .then(({ data }) => {
          commit("SET_REPORT_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  // Get Tranist API Pallets
  async fetchTransitPallets({ commit }, locations) {
    const transits = await Promise.all(
      locations?.map(async (loc) => {
        return await Vue.prototype.$api.transit
          .getTransitPallets(loc.id)
          .then(({ data }) => data);
      })
    );
    commit("SET_TRANSIT_PALLETS", transits);
  },

  // Get Tranist API Cycle
  async fetchTransits({ commit }, { locations, cycle_id }) {
    const transits = await Promise.all(
      locations.map(async (loc) => {
        return await Vue.prototype.$api.transit
          .getTransit(loc.id, cycle_id)
          .then(({ data }) => data);
      })
    );
    commit("SET_TRANSIT", transits);
  },
};

const getters = {
  getLocations: (state) => state.locations,
  getAreaPos: (state) => state.areasPosioned,
  getAreaTransit: (state) => state.areasTransit,
  getBoxStock: (state) => state.pallets,
  getTransit: (state) => state.transit,
  getTransitPallets: (state) => state.transitPallets,
  getBrands: (state) => state.brands,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
