import Vue from "vue";

const state = {
  disputesTable: [],
  dispute: [],
  causes: [],
  states: [],
};

const mutations = {
  SET_DISPUTES_TABLE: (state, payload) => {
    state.disputesTable = payload;
  },
  SET_DISPUTE: (state, payload) => {
    state.dispute = payload;
  },
  SET_CAUSES: (state, payload) => {
    state.causes = payload;
  },
  SET_STATES: (state, payload) => {
    state.states = payload;
  },
  SET_WAREHOUSE_USERS: (state, payload) => {
    state.warehouse_users = payload;
  },
};

const actions = {
  fetchDisputesTable({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.disputes
        .getDisputesTable(params)
        .then(({ data }) => {
          commit("SET_DISPUTES_TABLE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchDisputeDetail({ commit }, params) {
    console.log(params);
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.disputes
        .getDispute(params)
        .then(({ data }) => {
          commit("SET_DISPUTE", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchDisputeStates({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.disputes
        .getStates(params)
        .then(({ data }) => {
          commit("SET_STATES", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchDisputeCauses({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.disputes
        .getCauses(params)
        .then(({ data }) => {
          commit("SET_CAUSES", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  fetchWarehouseUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.disputes
        .getWarehouseUsers(params)
        .then(({ data }) => {
          commit("SET_WAREHOUSE_USERS", data);
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
  deleteDispute(store, id) {
    console.log(id);
    return new Promise((resolve, reject) => {
      Vue.prototype.$api.disputes
        .deleteDispute(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getDisputesTable: (state) => state.disputesTable,
  getDispute: (state) => state.dispute,
  getCauses: (state) => state.causes,
  getStates: (state) => state.states,
  getWarehouseUsers: (state) => state.warehouse_users,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
